<template>
    <div class="kmCenter">
        <div class="handBox">
            <el-button type="primary" @click="goBack" style="width: 92px;">返回</el-button>
            <span class="pageTopic">会员用户管理 2.1</span>
            <span style="margin-left: 20px;">用户总余额:</span>
            <span class="num-tips" >{{ userWallet }} 元</span>
            <el-button type="primary" style="margin-left: 20px;" size="mini" @click="getAllWallet()" :loading="walletLoading" >刷新</el-button>
        </div>
        <div class="searchBar">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
                <!-- <el-form-item label="预约时间" label-width="90px">
          <el-date-picker
            v-model="formInline.date"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            clearable
          >
          </el-date-picker>
        </el-form-item> -->
                <!-- <el-form-item label="状态" label-width="60px">
                    <el-select v-model="formInline.status" placeholder="请选择" clearable @clear="search">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="姓名" label-width="80px">
                    <el-input v-model="formInline.name" placeholder="请输入" @clear="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="formInline.phone" placeholder="请输入" @clear="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="主套系">
                <el-select
                    v-model="formInline.mainPlanDone"
                    placeholder="请选择"
                    clearable
                    @clear="search"
                >
                    <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    >
                    </el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="附加套系">
                <el-select
                    v-model="formInline.subPlanDone"
                    placeholder="请选择"
                    clearable
                    @clear="search"
                >
                    <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    >
                    </el-option>
                </el-select>
                </el-form-item>
                <el-form-item label="关键字">
                    <el-input v-model="formInline.keyWord" @input="search" placeholder="姓名/手机号/备注 模糊搜索" clearable>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="search" :loading="tableLoading"
                        style="width: 92px; margin-left: 20px">查询</el-button>
                    <el-button type="success" @click="openAdd" style="width: 92px; margin-left: 20px">导入</el-button>
                    <el-button type="warning" @click="exportExcel" style="width: 102px; margin-left: 20px">导出Excel
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="mainList">
            <el-table :data="tableData" style="width: 100%; margin-top: 10px" stripe border size="medium">
                <el-table-column align="center" label="序号" width="60">
                    <template slot-scope="scope">
                        <div>{{ scope.$index + (pages.page - 1) * pages.pageNum + 1 }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="date" label="充值日期" width="130"></el-table-column>
                <el-table-column align="center" prop="name" label="姓名" width="180"></el-table-column>
                <el-table-column align="center" prop="phone" label="手机号" width="180">
                </el-table-column>
                <el-table-column align="center" prop="status" label="套系情况" width="180">
                    <template slot-scope="scope">
                    <div>
                        <div
                            :class="[
                            { 'redFont': scope.row.mainPlanDone == 0 },
                            { 'blueFont': scope.row.mainPlanDone == '' },
                            { 'greenFont': scope.row.mainPlanDone == 1 },
                            ]"
                        >
                            主套系
                            <i class="el-icon-success" v-if="scope.row.mainPlanDone == 1"></i>
                            {{
                            scope.row.mainPlanDone == 0 
                                ? "未完成"
                                : scope.row.mainPlanDone == ''|| scope.row.mainPlanDone === undefined
                                ? "-"
                                : scope.row.mainPlanDone == 1
                                ? "已完成"
                                : ""
                            }}
                        </div>
                        <div
                            :class="[
                            { 'redFont': scope.row.subPlanDone == 0 },
                            { 'blueFont': scope.row.subPlanDone == '' },
                            { 'greenFont': scope.row.subPlanDone == 1 },
                            ]"
                        >
                            附加套系
                            <i class="el-icon-success" v-if="scope.row.subPlanDone == 1"></i>
                            {{
                            scope.row.subPlanDone == 0 
                                ? "未完成"
                                : scope.row.subPlanDone == ''|| scope.row.subPlanDone === undefined
                                ? "-"
                                : scope.row.subPlanDone == 1
                                ? "已完成"
                                : ""
                            }}
                        </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="price" label="充值金额" width="180">
                </el-table-column>
                <el-table-column align="center" prop="discount" label="折扣" width="180">
                </el-table-column>
                <el-table-column align="center" prop="wallet" label="余额" width="180">
                </el-table-column>

                <el-table-column align="center" prop="principal" label="门市" width="150">
                </el-table-column>
                <el-table-column align="center" prop="comment" label="备注">
                </el-table-column>
                <el-table-column align="center" label="操作" width="280" fixed="right">
                    <template slot-scope="scope">
                        <div>
                            <el-button size="small" style="margin-right:20px;" type="warning" @click="getRecord(scope)">
                                交易记录</el-button>
                            <el-button size="small" style="margin-right:20px;" type="primary"
                                @click="editConsumer(scope)">编辑</el-button>
                            <el-button size="small" type="danger" v-if="hasDelete" @click="deleteUser(scope)">删除</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="total,sizes, prev, pager, next" :current-page="pages.page * 1"
                :total="pages.total" :page-size="pages.pageNum * 1" style="margin-top: 20px"
                :page-sizes="[10, 12, 15, 17, 20, 25, 30, 100, 1000, pages.total]" @size-change="changePageSize"
                @current-change="changePage">
            </el-pagination>
        </div>

        <!-- 弹窗 1  -->
        <el-dialog :title="isEdit ? '修改信息' : '客户导入'" :visible.sync="dialogVisible" width="500px" top="20px">
            <el-form ref="form" :model="form" label-width="70px" label-position="left">
                <el-form-item label="姓名">
                    <el-input v-model="form.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="form.phone" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="充值金额">
                    <el-input v-model="form.price" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="余额">
                    <el-input v-model="form.wallet" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="折扣">
                    <el-input v-model="form.discount" placeholder="请输入"></el-input>
                </el-form-item>
                <!-- <el-form-item label="状态">
                    <el-select v-model="form.status" placeholder="请选择" style="width: 100%" clearable>
                        <el-option label="未处理" value="1"></el-option>
                        <el-option label="处理中" value="2"></el-option>
                        <el-option label="已完成" value="3"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="主套系" >
                    <el-switch  v-model="form.mainPlanDone" inactive-text="未完成" active-text="已完成" 
                    inactive-value="0" active-value="1"
                    active-color="#13ce66"  inactive-color="#95a5a6"></el-switch>
                </el-form-item>
                <el-form-item label="附加套系" >
                    <el-switch  v-model="form.subPlanDone" inactive-text="未完成"  active-text="已完成"
                        inactive-value="0" active-value="1"
                        active-color="#13ce66"  inactive-color="#95a5a6"></el-switch>
                </el-form-item>
                <el-form-item label="门市">
                    <el-input v-model="form.principal" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.comment" type="textarea" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button v-if="!isEdit" type="primary" @click="onSubmit" :loading="btnLoading">确定新增</el-button>
                <el-button :loading="btnLoading" v-else type="primary" @click="onEdit">确定修改</el-button>
            </span>
        </el-dialog>

        <!-- 弹窗 2 交易记录  -->
        <el-dialog title="交易记录" :visible.sync="dialogVisible2" width="900px">
            <div class="record-block">
                <div class="add-box">
                    <el-tabs type="border-card" style="flex:1;" v-model="currentTab">
                        <el-tab-pane label="充值" name="1">
                            <el-form ref="form" :model="recordForm" label-width="80px">
                                <el-form-item label="充值金额">
                                    <el-input v-model="recordForm.consumPrice"></el-input>
                                </el-form-item>
                                <el-form-item label="备注">
                                    <el-input type="textarea" v-model="recordForm.content"></el-input>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="消费" name="0">
                            <el-form ref="form" :model="recordForm" label-width="80px">
                                <el-form-item label="使用金额">
                                    <el-input v-model="recordForm.consumPrice"></el-input>
                                </el-form-item>
                                <el-form-item label="使用内容">
                                    <el-input type="textarea" v-model="recordForm.content"></el-input>
                                </el-form-item>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                    <div style="margin-top: 20px;display:flex;justify-content:flex-end">
                        <el-button type="primary" @click="addRecord">添加</el-button>
                    </div>
                </div>
                <div class="record-box">
                    <el-timeline>
                        <el-timeline-item :timestamp="item.date" placement="top"
                            v-for="(item, index) in nowRow.recordList" :key="index">
                            <el-card style="position: relative;">
                                <div :style="{ color: (item.type == 0 ? 'red' : 'green') }" class="item-title">
                                    {{ (item.type == 1 ? '充值 +' : '消费 -') + item.consumPrice + '元' }}
                                    <span style="width:80px;display:inline-block;"></span>
                                    <span v-if="index < nowRow.recordList.length - 1" style="color:#333">余额 {{
                                            getWallet(item)
                                    }}元</span>
                                </div>
                                <p>{{ item.content }} </p>
                                <!-- <span v-if="index<nowRow.recordList.length-1" style="position: absolute; right:10px;top:10px" @click="deleteRecord(index)" >
                                    <el-button type="danger" icon="el-icon-delete" size="mini" circle></el-button>
                                </span> -->
                            </el-card>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible2 = false">关 闭</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import axios from "axios";

/**
 * @desc 防抖函数：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
 * @param {Function} func 函数
 * @param {Number} wait 延迟执行毫秒数
 * @param {Boolean} immediate true 表示立即执行，false 表示非立即执行
 */
 let timeout

function debounce(func, wait = 500, immediate = false) {
  // 清除定时器
  if (timeout) clearTimeout(timeout)
  // 立即执行，此类情况一般用不到
  if (immediate) {
    let callNow = !timeout
    timeout = setTimeout(() => {
      timeout = null
    }, wait)
    if (callNow) typeof func === 'function' && func()
  } else {
    // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
    timeout = setTimeout(() => {
      typeof func === 'function' && func()
    }, wait)
  }
}

export default {
    name: "vipConsumer",
    components: {},
    computed: {
        getWallet() {
            return function (item) {
                if (item.type == 1) {
                    return item.wallet * 1 + item.consumPrice * 1
                } else {
                    return item.wallet * 1 - item.consumPrice
                }
            }
        }
    },
    data() {
        return {
            formInline: {
                name: "",
                phone: "",
                date: "",
                keyWord: "",
                status: "",
                mainPlanDone: "",
                subPlanDone: "",
            },
            pages: {
                page: 1,
                pageNum: 15,
                total: 0,
            },
            tableData: [],
            dialogVisible: false,
            recordForm: {
                type: 0,  // 0-消费 1-充值
                consumPrice: 0, // 消费金额
                content: '',  // 消费内容
                date: '', //时间
            },
            // 添加导入数据
            form: {
                _id: "",
                name: "",
                phone: "",
                principal: "",
                comment: "",
                status: "",
                price: "",
                discount: "",  //折扣
                recordList: [],
                mainPlanDone: "0",
                subPlanDone: "0",
            },
            isEdit: false, // 判断是否为编辑
            options: [
                {
                    value: "1",
                    label: "未处理",
                },
                {
                    value: "2",
                    label: "处理中",
                },
                {
                    value: "3",
                    label: "已完成",
                },
            ],
            options2: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: '1',
                    label: "已完成",
                },
                {
                    value: '0',
                    label: "未完成",
                },
            ],
            tableLoading: false,
            btnLoading: false,
            dialogVisible2: false,
            currentTab: 0,
            nowRow: {
                recordList: []
            },
            hasDelete: false,
            userWallet: 0,
        };
    },
    created() { 
        this.getAllWallet();
    },
    mounted() {
        this.getData();

        let nowDate = new Date();
        let year = nowDate.getFullYear();
        let month = nowDate.getMonth() * 1 + 1;

        if(localStorage.getItem('token') == ('21232f297a57a5a743894a0e4a801fc3ef775988943825d2871e1cfa75473ec0' + year + month)) {
            this.hasDelete = true
        }
    },
    methods: {
        // 获取账户余额
        getAllWallet() {
            this.walletLoading = true;
            axios.get(this.$baseUrl + "/vipConsumer/vipAccountWallet").then(res=>{
                console.log(res,'获取总计金额')
                this.walletLoading = false;
                this.userWallet = res.data.data.wallet.toFixed(2);
                this.$message({
                    duration: "1000",
                    showClose: true,
                    message: "刷新成功",
                    type: "Success",
                });
            })
            .catch(err=>{
                console.log(err)
                this.walletLoading = false;
            })
        },
        goBack() {
            this.$router.go(-1);
        },
        search() {
            debounce(()=>{
                console.log('执行了')
                this.getAllWallet();
                // 户外帐篷-1 拉杆箱-2 溜娃神器-3
                this.pages.page = 1;
                this.getData();
            }, 500)
            console.log('执行了')
            
        },
        changePage(val) {
            this.pages.page = val * 1;
            this.getData();
        },
        giftTypeChange() {
            // this.$set(this.form,'type',val);
            // 不强制更新 会导致数据变化视图没有变化
            this.$forceUpdate();
        },
        // 获取数据
        async getData() {
            this.tableLoading = true;
            await axios
                .get(
                    this.$baseUrl +
                    "/vipConsumer?" +
                    "page=" +
                    this.pages.page +
                    "&pageNum=" +
                    this.pages.pageNum +
                    "&name=" +
                    this.formInline.name +
                    "&phone=" +
                    this.formInline.phone +
                    "&mainPlanDone=" +
                    this.formInline.mainPlanDone +
                    "&subPlanDone=" +
                    this.formInline.subPlanDone +
                    "&status=" +
                    this.formInline.status +
                    "&date=" +
                    this.formInline.date +
                    "&keyWord=" +
                    this.formInline.keyWord
                )
                .then((res) => {
                    setTimeout(() => {
                        this.tableLoading = false;
                    }, 200);
                    this.tableData = res.data.list;
                    this.pages = res.data.pages;
                })
                .catch((err) => {
                    this.tableLoading = false;
                    console.log(err);
                });
        },
        //导出excel
        exportExcel() {
            axios
                .get(
                    this.$baseUrl +
                    "/vipConsumer/exportExcel?" +
                    "page=" +
                    this.pages.page +
                    "&pageNum=" +
                    this.pages.pageNum +
                    "&name=" +
                    this.formInline.name +
                    "&phone=" +
                    this.formInline.phone +
                    "&mainPlanDone=" +
                    this.formInline.mainPlanDone +
                    "&subPlanDone=" +
                    this.formInline.subPlanDone +
                    "&status=" +
                    this.formInline.status +
                    "&date=" +
                    this.formInline.date +
                    "&keyWord=" +
                    this.formInline.keyWord
                )
                .then((res) => {
                    console.log("导出");
                    window.open(res.data.url);
                });
        },
        // 点击导入
        openAdd() {
            this.form = {
                _id: "",
                name: "",
                phone: "",
                principal: "",
                price: "",
                comment: "",
                status: "",
                discount: "",
                recordList: [],
                mainPlanDone: "0",
                subPlanDone: "0",
            };
            this.dialogVisible = true;
            this.isEdit = false;
        },
        // 点击交易记录
        getRecord(scope) {
            this.nowRow = scope.row;
            this.dialogVisible2 = true;
            this.recordForm = {
                type: this.currentTab,
                consumPrice: 0, // 消费金额
                wallet: this.nowRow.wallet,
                content: '',  // 消费内容
                date: '', //时间
            }
        },
        // 添加记录
        addRecord() {
            this.recordForm.date = new Date().toLocaleString();
            this.recordForm.type = this.currentTab;
            let param = { ...this.nowRow };
            param.recordList.unshift({ ...this.recordForm });
            param.wallet = this.getWallet({ ...this.recordForm })
            axios
                .post(this.$baseUrl + "/vipConsumer/editInfo", param)
                .then((res) => {
                    if (res.data.code == 200) {
                        console.log(res.data, "修改数据");
                        this.recordForm.wallet = param.wallet;
                        this.$message({
                            duration: "2000",
                            showClose: true,
                            message: "修改成功！",
                            type: "success",
                        });
                        this.search();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 删除记录 记录不允许删除
        deleteRecord(index) {
            this.nowRow.recordList.splice(index, 1)
            let param = { ...this.nowRow };
            param.wallet =
                axios
                    .post(this.$baseUrl + "/vipConsumer/editInfo", param)
                    .then((res) => {
                        if (res.data.code == 200) {
                            console.log(res.data, "修改数据");
                            this.$message({
                                duration: "2000",
                                showClose: true,
                                message: "删除成功！",
                                type: "success",
                            });
                            this.search();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
        },
        // 点击编辑，编辑已有用户信息
        editConsumer(scope) {
            this.isEdit = true;
            this.dialogVisible = true;
            let { name, phone, principal, comment, status, _id, price, recordList, discount, wallet, mainPlanDone, subPlanDone } = scope.row;
            this.form = { name, phone, principal, comment, status, _id, price, recordList, discount, wallet, 
                mainPlanDone: mainPlanDone?mainPlanDone:'0',
                subPlanDone: subPlanDone?subPlanDone:'0',
            };
        },
        // 删除用户
        deleteUser(scope) {
            this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let param = {
                    ...scope.row
                }
                axios
                .post(this.$baseUrl + "/vipConsumer/deleteUser", param).then(res=>{
                    console.log(res)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getData();
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 确认添加
        onSubmit() {
            this.btnLoading = true;
            let param = { ...this.form };
            let date = new Date().getFullYear() + ' ' + (new Date().getMonth() * 1 + 1) + "-" + new Date().getDate();
            param.date = date;
            delete param.type;
            delete param._id;
            param.wallet = param.wallet?param.wallet:param.price?param.price:0;
            console.log(param, "新增");
            if (param.name.trim() == "" && param.phone.trim() == "") {
                this.$message({
                    showClose: true,
                    message: "请填写姓名或手机号",
                    type: "warning",
                });
                this.btnLoading = false;
                return;
            }
            param.recordList = [
                {
                    type: '1',
                    consumPrice: param.price, // 消费金额
                    wallet: param.wallet,
                    content: '首次充值',  // 消费内容
                    date: date, //时间
                }
            ]
            axios
                .post(this.$baseUrl + "/vipConsumer/addConsumer", param)
                .then((res) => {
                    if (res.data.code == 200) {
                        console.log(res.data, "添加成功");
                        this.$message({
                            duration: "2000",
                            showClose: true,
                            message: "添加成功！",
                            type: "success",
                        });
                        this.dialogVisible = false;
                        this.btnLoading = false;
                        this.search();
                    }
                    if (res.data.code == 302) {
                        this.$message({
                            duration: "2000",
                            showClose: true,
                            message: "重复手机号请检查输入信息！",
                            type: "warning",
                        });
                        this.btnLoading = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.btnLoading = false;
                });
        },
        // 确认编辑
        onEdit() {
            this.btnLoading = true;
            let param = { ...this.form };
            let date = new Date().getFullYear() + ' ' + (new Date().getMonth() * 1 + 1) + "-" + new Date().getDate();
            param.date = date;
            delete param.type;
            console.log(param, "编辑");
            if (param.name.trim() == "" && param.phone.trim() == "") {
                this.$message({
                    showClose: true,
                    message: "请填写姓名或手机号",
                    type: "warning",
                });
                this.btnLoading = false;
                return;
            }
            axios
                .post(this.$baseUrl + "/vipConsumer/editInfo", param)
                .then((res) => {
                    if (res.data.code == 200) {
                        console.log(res.data, "修改数据");
                        this.$message({
                            duration: "2000",
                            showClose: true,
                            message: "修改成功！",
                            type: "success",
                        });
                        this.btnLoading = false;
                        this.search();
                        this.dialogVisible = false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.btnLoading = false;
                });
        },
        // 修改每页选择数量
        changePageSize(val) {
            this.pages.pageNum = val;
            this.getData();
        },
    },
};
</script>
<style scoped lang="less">
.kmCenter {
    height: 100%;
    width: 100%;
    padding: 10px 50px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;

    .searchBar,
    .manlist {
        margin: 10px auto;
    }

    .searchBar {
        border: 1px solid #284faa;
        border-radius: 4px;
        padding-top: 22px;
    }

    .mainList {
        padding: 10px 0;
    }

    .redFont {
        font-weight: 600;
        color: #492525;
    }

    .blueFont {
        font-weight: 600;
        color: #2e62cd;
    }

    .greenFont {
        font-weight: 600;
        color: #349e69;
    }

    .record-block {
        display: flex;

        .add-box {
            display: flex;
            flex-direction: column;
            width: 300px;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
            margin: 20px;
            padding: 13px;
        }

        .record-box {
            margin-top: 20px;
            flex: 1;
            height: 400px;
            overflow-y: auto;
        }
    }

    .item-title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .num-tips {
        margin-left: 10px;
        font-weight: bold;
        font-size: 22px;
    }

}
</style>